<template>
  <div class="Rowhouse_list">
    <app-header title="办理排房" :isShowBack="true"></app-header>
    <div class="content-top">
      {{rowData.apartmentName}}{{rowData.buildName}}{{rowData.roomNo}}房间
      <span>/</span>
      {{rowData.roomTypeStr}}
      <span>/</span>
      {{["长租", "短租", "长短租"][rowData.rentalStyle]}}
      <span>/</span>
      <label>{{rowData.rentalFee}} {{rowData.rentalStyle==0?'元/月':'元/天'}}</label>
    </div>
    <div class="content-box">
      <el-form :inline="true" :model="rowRoomForm" label-width="105px" :rules="rules" ref="rowRoomForm"
        class="form-box">
        <!-- <el-form-item label="订单来源">
          <el-select v-model="rowRoomForm.orderFrom" placeholder="请选择" clearable>
            <el-option
              v-for="item in Roomlistings"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model.trim="rowRoomForm.realNum" clearable></el-input>
        </el-form-item>
        <el-form-item label="订单总金额（元）">
          <el-input v-model.trim="rowRoomForm.realMoney" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="搜索房客" prop="searchTenant" style="width:100%">
          <el-select v-model="rowRoomForm.searchTenant" filterable remote clearable reserve-keyword placeholder="手机号"
            :remote-method="remoteMethod" @change="selectTrigger(rowRoomForm.searchTenant)" :loading="loading">
            <el-option v-for="item in searchTenantOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房客姓名" prop="realname">
          <el-input v-model.trim="rowRoomForm.realname" clearable
            :disabled="searchohabitantCertFlage||searchohabitantPhoneFlage"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender" label-width="120px">
          <div class="width70">
            <el-select v-model="rowRoomForm.gender" placeholder="请选择"
              :disabled="searchohabitantCertFlage||searchohabitantPhoneFlage"
             >
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="是否有证件" prop="haveCert" label-width="120px">
          <div class="width70">
            <el-select v-model="rowRoomForm.haveCert" placeholder="请选择"
              :disabled="searchohabitantCertFlage||searchohabitantPhoneFlage || rowRoomForm.searchCertChangePhoneFlag || rowData.isKey=='1'"
              @change="haveCertChange">
              <el-option v-for="item in haveCertList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item v-if="rowRoomForm.haveCert=='1'" label="证件类型" prop="certType">
          <el-select v-model="rowRoomForm.certType" placeholder="请选择" clearable
            :disabled="searchohabitantPhoneFlage||searchohabitantCertFlage || rowData.isKey=='1'"
            @change="certTypeChange(rowRoomForm.certType)">
            <el-option v-for="item in certTypeOptions" :key="item.value" :label="item.label" :value="item.value"
              v-show="item.value!='7' || (rowData.apartmentCode && rowData.apartmentType=='7' && item.value=='7')">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="rowRoomForm.haveCert=='1'" :label="rowRoomForm.certType=='7'?'学号/职工号':'证件号码'" prop="certNum"
          style="width:420px">
          <el-input v-model.trim="rowRoomForm.certNum" clearable @input="searchCertNumTenant(rowRoomForm,'1')"
            :disabled="searchohabitantPhoneFlage" :placeholder="rowRoomForm.certType=='7'?'学号/职工号':'证件号码'"
            class="lt-select fl" maxlength="24"></el-input>
          <el-button class="rt-btn" type="primary" @click="cardReadingBtn('1')">读身份证</el-button>
        </el-form-item>
        <!-- <el-form-item label="短信验证" prop="realphone" v-if="!isCertifiedTenant">
          <el-input style="width:134px" v-model.trim="rowRoomFormp.authCode" maxlength="6" placeholder="请输入验证码">
          </el-input>
          <span class="verificationCode" @click="send(rowRoomForm.realphone,1)">{{showTime ? count : '获取验证码'}}</span>

        </el-form-item> -->
        <!-- 换行 -->
        <div v-if="rowRoomForm.haveCert=='1'"></div>
        <el-form-item label="上传证件照片" prop="cardFrontPic" v-if="rowData.isUploadPic=='1'">
          <div class="picCont">
            <div class="pic-lt">
              <el-upload class="avatar-uploader" accept="image/*" list-type="picture-card"
                :action="UPLOAD_URL + '/user-service/weChat/uploadPic'" :file-list="rowRoomForm.cardFrontPic"
                :on-preview="onPreviewByUploadImg" :before-upload="onBeforeUpload"
                :before-remove="onBeforeRemoveByUploadImg" :on-success="onSuccessByUploadImgOnly('cardFrontPic')"
                :on-remove="onHandleRemove('cardFrontPic')" :on-exceed="onExceed"
                :class="{'one-upload-disabled': (rowRoomForm.cardFrontPic && rowRoomForm.cardFrontPic.length>0)}">
                <i v-if="rowRoomForm.cardFrontPic && rowRoomForm.cardFrontPic.length==0" class="el-icon-plus"></i>
              </el-upload>
              <!-- <p>（人像面）</p> -->
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="rowRoomForm.haveCert=='1'" label="是否有手机号码" prop="havePhone" label-width="120px">
          <div class="width70">
            <el-select v-model="rowRoomForm.havePhone" placeholder="请选择"
              :disabled="searchohabitantCertFlage||searchohabitantPhoneFlage || rowRoomForm.searchCertChangePhoneFlag"
              @change="havePhoneChange">
              <el-option v-for="item in havePhoneList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item v-if="rowRoomForm.havePhone=='1'||rowRoomForm.haveCert=='0'" label="房客电话" prop="realphone">
          <el-input v-model.trim="rowRoomForm.realphone" placeholder="房客电话" clearable
            @input="searchRealphoneTenant(rowRoomForm.realphone)" :disabled="searchohabitantCertFlage" maxlength="11"
            style="width:135px"></el-input>
        </el-form-item>
        <el-form-item v-if="rowRoomForm.havePhone=='0' && rowRoomForm.haveCert=='1'" label="登录密码" prop="passWord">
          <el-input :type="rowRoomForm.passWordType" v-model.trim="rowRoomForm.passWord" @focus="passWordFocus"
            placeholder="登录密码" clearable maxlength="12" style="width:135px">
          </el-input>
        </el-form-item>
        <el-form-item label="租赁模式" v-if="rowData.rentalStyle==2" prop="rentalStyle">
          <div class="width90">
            <el-select v-model="rowRoomForm.rentalStyle" placeholder="请选择" @change="changeSelect">
              <el-option v-for="item in rentalStyleList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="租期" v-if="rowData.rentalStyle==0||LeasingModel=='0'" prop="leaseTerm">
          <el-select v-model="rowRoomForm.leaseTerm" placeholder="请选择">
            <el-option v-for="item in monthOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租期" v-else-if="rowData.rentalStyle==1||LeasingModel=='1'" prop="sortleaseTerm"
          style="width:710px">
          <div class="width70">
            <el-date-picker v-model="rowRoomForm.sortleaseTerm" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
            </el-date-picker>
            <span style="margin-left:10px;color:#999999">开始日期的14:00:00至结束日期的14:00:00</span>
          </div>
        </el-form-item>
        <el-form-item label="学号/职工号" prop="studentCode" v-if="rowData.apartmentType == '7' && rowRoomForm.certType!=='7'">
          <el-input v-model.trim="rowRoomForm.studentCode" clearable ></el-input>
        </el-form-item>
        <div class="dashed-box"></div>
        <div class="list-box">
          <div class="list-item" v-for="(item, index) in cohabitantList" :key="index">
            <div class="list-col">
              <label>{{index+1}}、搜索同住人</label>
              <el-select v-model="item.searchCohabitant" filterable remote reserve-keyword placeholder="手机号"
                :remote-method="remoteMethodCohabitant" @change="selectCohabitant(item.searchCohabitant,index)"
                :loading="loading" clearable>
                <el-option v-for="item in searchCohabitantOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="list-col">
              <label>同住人姓名</label>
              <el-input class="el-name" v-model="item.cohabitantName" :disabled="item.searchohabitantFlage"
                placeholder="请输入同住人姓名"></el-input>
            </div>
            <div class="list-col">
              <label>性别</label>
              <div class="width100">
                <el-select v-model="item.cohabitantGender" placeholder="请选择"
                  :disabled="item.searchohabitantFlage"
                  >
                  <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="list-col">
              <label>是否有证件</label>
              <div class="width100">
                <el-select v-model="item.haveCert" placeholder="请选择"
                  :disabled="item.searchohabitantFlage || item.searchCertChangePhoneFlag || rowData.isKey=='1'"
                  @change="cohabitantHaveCertChange(index)">
                  <el-option v-for="item in haveCertList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- <div class="list-col" v-if="!item.isCertifiedChohabitant">
              <label>短信验证</label>
              <el-input class="verCodeInput" v-model.trim="item.authCode" placeholder="请输入验证码"></el-input>
              <span class="verCodeText"
                @click="sendCohabitantCode(item.cohabitantPhone,index)">{{!item.showTime ? "获取验证码" : item.count}}</span>
            </div> -->
            <div class="list-col" v-if="item.haveCert=='1'">
              <label>证件类型</label>
              <el-select v-model="item.cohabitantType" :disabled="item.searchohabitantFlage || rowData.isKey=='1'"
                placeholder="请选择证件类型" clearable>
                <el-option v-for="item in certTypeOptions" :key="item.value" :label="item.label" :value="item.value"
                  v-show="item.value!='7' || (rowData.apartmentCode && rowData.apartmentType == '7' && item.value=='7')">
                </el-option>
              </el-select>
            </div>
            <div class="list-col" v-if="item.haveCert=='1'">
              <label>{{item.cohabitantType=='7'?'学号/职工号':'证件号码'}}</label>
              <el-input v-model.trim="item.cohabitantCert" :placeholder="item.cohabitantType=='7'?'学号/职工号':'证件号码'" clearable
                @input="searchCertNumCohabitant(item,index,'1')" :disabled="item.searchohabitantPhoneFlage"
                maxlength="24">
              </el-input>
            </div>
            <div class="list-col" v-if="item.haveCert=='1'">
              <el-button class="el-btn" type="primary" @click="cardReadingBtn('2',index)">读身份证</el-button>
            </div>
            <div class="list-col" v-if="rowData.isUploadPic=='1'">
              <label>上传证件照片</label>
              <el-input v-model.trim="item.cardText" placeholder="点击上传按钮上传" clearable disabled style="width:200px">
              </el-input>
              <el-button class="el-btn" type="primary" @click="uploadCardBtn(index)">上传</el-button>
            </div>
            <div class="list-col" v-if="item.haveCert=='1'">
              <label>是否有手机号码</label>
              <div class="width100">
                <el-select v-model="item.havePhone" placeholder="请选择"
                  :disabled="item.searchohabitantFlage || item.searchCertChangePhoneFlag"
                  @change="cohabitantHavePhoneChange(index)">
                  <el-option v-for="item in havePhoneList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="list-col" v-if="item.havePhone=='1' || item.haveCert=='0'">
              <label>电话</label>
              <el-input v-model.trim="item.cohabitantPhone" placeholder="同住人电话" clearable
                @input="searchRealphoneCohabitant(item.cohabitantPhone,index)" :disabled="item.searchohabitantCertFlage"
                maxlength="11"></el-input>
            </div>
            <div class="list-col" v-if="rowData.apartmentType == '7' && item.cohabitantType!=='7'">
              <label>学号/职工号</label>
              <el-input class="el-name" v-model="item.studentCode"></el-input>
            </div>
            <div class="list-col" v-if="item.havePhone=='0' && item.haveCert=='1'">
              <label>登录密码</label>
              <el-input :type="item.passWordType" v-model.trim="item.passWord" placeholder="登录密码" maxlength="12"
                clearable @focus="passWordItemFocus(index)"></el-input>
            </div>
            <div class="list-buts">
              <el-button class="list-but" type="primary" icon="el-icon-plus" @click="addItem(index)"></el-button>
              <el-button class="list-but" type="danger" icon="el-icon-minus" @click="delItem(index)"></el-button>
            </div>
          </div>
        </div>
        <!-- <el-form-item label="关于合同说明" v-if="rowData.rentalStyle==0">
          <span class="description">房客扫码认证成功后，确认签署租房电子合同，签署完成后即可使用小程序开门。</span>
        </el-form-item> -->
        <div class="bottom-box">
          <el-button class="bottom_boxBtn" @click="submitBtn('rowRoomForm')" type="primary">提交</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog title="上传证件照片" :visible.sync="dialogUplodCardShow" width="630px" :close-on-click-modal="false">
      <el-form :inline="true" :model="cohabitantList[curRoommateIndex]" label-width="100px" :rules="rules"
        ref="cohabitantList[curRoommateIndex]" class="form-box">
        <el-form-item label="证件照片">
          <el-upload class="avatar-uploader" accept="image/*" list-type="picture-card"
            :action="UPLOAD_URL + '/user-service/weChat/uploadPic'"
            :file-list="cohabitantList[curRoommateIndex].cardFrontPic" :on-preview="onPreviewByUploadImg"
            :before-upload="onBeforeUpload" :before-remove="onBeforeRemoveByUploadImg"
            :on-success="onSuccessByUploadImgOnly('cardFrontPic',curRoommateIndex)"
            :on-remove="onHandleRemove('cardFrontPic',curRoommateIndex)" :on-exceed="onExceed"
            :class="{'one-upload-disabled': (cohabitantList[curRoommateIndex].cardFrontPic && cohabitantList[curRoommateIndex].cardFrontPic.length>0)}">
            <i v-if="cohabitantList[curRoommateIndex].cardFrontPic && cohabitantList[curRoommateIndex].cardFrontPic.length==0"
              class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="bottom-box">
        <el-button class="bottom_boxBtn" @click="dialogBtn" type="primary">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogImgVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
  import Vue from "vue";
  const TIME_COUNT = 60; //更改倒计时时间
  let tenantCertType = '1'; //房客证件类型
  let codeValue = '';
  let validID = (rule, value, callback) => {
    if (!Vue.prototype.checkIdCard(value)) {
      callback(new Error("身份证号码不正确"));
    } else {
      callback();
    }
  };
  let validMobile = (rule, value, callback) => {
    let reg = /^1[3-9](\d){9}$/;
    if (!reg.test(value)) {
      callback(new Error("手机号码格式不对"));
    } else {
      callback();
    }
  };
  let validPsword = (rule, value, callback) => {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,12}$/;
    let reg2 = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
    if (reg2.test(value)) {
      callback(new Error("密码格式不对(只能包含大小写字母、数字、英文符号)"));
    } else if (!reg.test(value)) {
      callback(new Error("密码格式不对(密码为6~12位含大小写字母和数字组成)"));
    } else {
      callback();
    }
  };
  let validCertNum = (rule, value, callback) => {
    let reg = /^[A-z0-9]{4,24}$/;
    if (tenantCertType == '7') {
      if (!value) {
        callback(new Error("请输入学号/职工号"));
      } else if (!reg.test(value)) {
        callback(new Error("学号/职工号格式不对"));
      } else {
        callback();
      }
    } else if (tenantCertType == '1') {
      if (!value) {
        callback(new Error("请输入证件号码"));
      } else if (!Vue.prototype.checkIdCard(value)) {
        callback(new Error("证件号码格式不对"));
      } else {
        callback();
      }
    } else {
      if (!value) {
        callback(new Error("请输入证件号码"));
      } else if (!reg.test(value)) {
        callback(new Error("证件号码格式不对"));
      } else {
        callback();
      }
    }
  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback(new Error("请输入房客姓名"));
    } else {
      //房客姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("房客姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validStudentCode = (rule, value, callback) => {
    let reg = /^[A-Za-z0-9]{4,24}$/;
      if(value){
        if (!reg.test(value)) {
          callback(new Error("学号/职工号格式不正确"));
        } else {
          callback();
        }
      }else {
        callback();
      }
  }
  export default {
    data() {
      let _this = this;
      return {
        actionUrl: '',
        isCertifiedTenant: false, //是否已认证
        cohabitantVal: "",
        showTime: false,
        count: 0,
        rowData: {},
        LeasingModel: "",
        curRoommateIndex: 0,
        dialogUplodCardShow: false,
        rowRoomForm: {
          rentalStyle: "",
          orderFrom: "0",
          realNum: "",
          realMoney: "",
          searchTenant: "",
          realname: "",
          studentCode: "",
          realphone: "",
          certType: "1",
          gender: "1",
          certNum: "",
          leaseTerm: "",
          sortleaseTerm: "",
          cardFrontPic: [],
          passWordType: 'text',
          searchCertChangePhoneFlag: false
        },
        rentalStyleList: [{
            value: "0",
            label: "长租"
          },
          {
            value: "1",
            label: "短租"
          }
        ],
        havePhoneList: [{
            value: "0",
            label: "无"
          },
          {
            value: "1",
            label: "有"
          }
        ],
        haveCertList: [{
            value: "0",
            label: "无"
          },
          {
            value: "1",
            label: "有"
          }
        ],
        genderList: [{
            value: "1",
            label: "男"
          },
          {
            value: "2",
            label: "女"
          }
        ],
        monthOption: [],
        cohabitantList: [],
        roomTemplate: {
          cohabitantName: "",
          cohabitantGender: "1",
          cohabitantPhone: "",
          cohabitantType: "1",
          cohabitantCert: "",
          searchohabitantFlage: false,
          searchohabitantCertFlage: false,
          lastAuthentication: false,
          //同住人是否已认证
          certifiedFlage: false,
          cardFrontPic: [],
          havePhone: '1',
          passWord: codeValue,
          passWordType: 'text',
          searchCertChangePhoneFlag: false,
          studentCode: "",
        },
        Roomlistings: [{
            value: "0",
            label: "携程"
          },
          {
            value: "1",
            label: "美团"
          },
          {
            value: "2",
            label: "去哪儿网"
          },
          {
            value: "3",
            label: "途牛"
          },
          {
            value: "4",
            label: "飞猪"
          },
          {
            value: "5",
            label: "中介"
          },
          {
            value: "6",
            label: "其他"
          }
        ],
        rules: {
          realname: [{
            required: true,
            validator: validName,
            // message: "请输入房客姓名",
            trigger: ['change', 'blur']
          }],
          haveCert: [{
            required: true,
            message: "是否有证件",
            trigger: ['change']
          }],
          gender: [{
            required: true,
            message: "请选择性别",
            trigger: ['change']
          }],
          realphone: [{
            required: true,
            validator: validMobile,
            // message: "请输入房客电话",
            trigger: ['change', 'blur']
          }],
          passWord: [{
            required: true,
            validator: validPsword,
            trigger: ['change', 'blur']
          }],
          certType: [{
            required: true,
            message: "请选择证件类型",
            trigger: "change"
          }],
          certNum: [{
            required: true,
            validator: validCertNum,
            trigger: ['change', 'blur']
          }],
          cardFrontPic: [{
            required: true,
            message: "请上传证件照片",
          }],
          havePhone: [{
            required: true,
            message: "请选择是否有手机号码",
            trigger: "change"
          }],
          rentalStyle: [{
            required: true,
            message: "请选择租赁模式",
            trigger: "change"
          }],
          leaseTerm: [{
            required: true,
            message: "请选择租期",
            trigger: "change"
          }],
          sortleaseTerm: [{
            required: true,
            message: "请选择租期",
            trigger: "change"
          }],
          studentCode: [{
              required: false,
              trigger: ['change'],
              validator: validStudentCode,
          }],
        },
        pickerOptions: {
          disabledDate(time) {
            let nowTimeNum = moment().format("YYYY-MM-DD 14:00:00");
            if (moment().format("YYYY-MM-DD HH:mm:ss") > nowTimeNum) {
              return time.getTime() < Date.now() - 8.64e7;
            } else {
              return time.getTime() < Date.now() - 2 * 24 * 3600 * 1000;
            }
          }
        },
        apartmentList: {}, // 房源列表
        typeIdList: {}, // 房型列表
        searchTenantOptions: [],
        searchCohabitantOptions: [],
        list: [],
        loading: false,
        searchohabitantCertFlage: false, //证件号码查询实名认证，其他不可修改
        searchohabitantPhoneFlage: false, //手机号码查询实名认证，其他不可修改
        lastAuthentication: false, //上一次查询是否是实名认证，如果是，下一次查询用户不存在时清空数据
        dialogImgVisible: false,
        dialogImageUrl: '',
      };
    },
    created() {
      this.showTime = false;
      console.log("Rowhouse created!!");
      this.rowData = this.cache.getLS("Rowhouse");
      this.rowRoomForm = {
        rentalStyle: "",
        orderFrom: "0",
        realNum: "",
        realMoney: "",
        realname: "",
        studentCode: "",
        gender: "1",
        realphone: "",
        certType: "1",
        certNum: "",
        leaseTerm: "",
        sortleaseTerm: "",
        havePhone: '1',
        passWord: codeValue,
        cardFrontPic: [],
        passWordType: 'text',
        pwSearchFlag: false, //接口查询密码回来的标识
        searchCertChangePhoneFlag: false //是否可以修改手机号
      };
      tenantCertType = '1';
      this.roomTemplate = {
        cohabitantName: "",
        cohabitantGender: "1",
        cohabitantPhone: "",
        cohabitantType: "1",
        cohabitantCert: "",
        searchohabitantFlage: false,
        searchohabitantCertFlage: false,
        lastAuthentication: false,
        searchohabitantPhoneFlage: false,
        //同住人是否已认证
        certifiedFlage: false,
        cardFrontPic: [],
        havePhone: '1',
        passWord: codeValue,
        passWordType: 'text',
        pwSearchFlag: false,
        searchCertChangePhoneFlag: false,
        studentCode: "",
      };
      this.searchohabitantCertFlage = false;
      this.lastAuthentication = false;
      this.searchohabitantPhoneFlage = false;
      this.curRoommateIndex = 0;
      let _list = [];
      for (let i = 0; i < 1; i++) {
        _list.push(JSON.parse(JSON.stringify(this.roomTemplate)));
      }
      this.cohabitantList = _list;

    },
    mounted() {},
    activated() {
      console.log("Rowhouse activated!!");
      codeValue = this.AES.decrypt('BFoiygVGMp233P8FPFgfvw==')
      this.rowData = this.cache.getLS("Rowhouse");
      this.rowRoomForm = {
        rentalStyle: "",
        orderFrom: "0",
        realNum: "",
        realMoney: "",
        realname: "",
        studentCode: "",
        gender: "1",
        realphone: "",
        certType: "1",
        certNum: "",
        leaseTerm: "",
        sortleaseTerm: "",
        havePhone: '1',
        passWord: codeValue,
        cardFrontPic: [],
        passWordType: 'text',
        pwSearchFlag: false, //接口查询密码回来的标识
        searchCertChangePhoneFlag: false,
        haveCert: '1', //是否有证件号码
      };
      tenantCertType = '1';
      this.roomTemplate = {
        cohabitantName: "",
        cohabitantGender: "1",
        cohabitantPhone: "",
        cohabitantType: "1",
        cohabitantCert: "",
        searchohabitantFlage: false,
        searchohabitantCertFlage: false,
        lastAuthentication: false,
        searchohabitantPhoneFlage: false,
        //同住人是否已认证
        certifiedFlage: false,
        cardFrontPic: [],
        havePhone: '1',
        passWord: codeValue,
        passWordType: 'text',
        pwSearchFlag: false,
        searchCertChangePhoneFlag: false,
        haveCert: '1', //是否有证件号码
        studentCode: "",
      };
      this.searchohabitantCertFlage = false;
      this.lastAuthentication = false;
      this.searchohabitantPhoneFlage = false;
      this.curRoommateIndex = 0;
      let array = [];
      for (var i = 3; i <= 100; i++) {
        array.push({
          value: i,
          label: i + "个月"
        });
      }
      this.monthOption = array;
      let _list = [];
      for (let i = 0; i < 1; i++) {
        _list.push(JSON.parse(JSON.stringify(this.roomTemplate)));
      }
      this.cohabitantList = _list;
      this.LeasingModel = '';
      this.dialogImgVisible = false;
      this.dialogImageUrl = '';
      this.$nextTick(() => {
          if (this.$refs["rowRoomForm"]) {
              this.$refs["rowRoomForm"].resetFields();
          }
      })

    },
    deactivated() {
      this.rowRoomForm = {
        rentalStyle: "",
        orderFrom: "0",
        realNum: "",
        realMoney: "",
        realname: "",
        studentCode: "",
        gender: "1",
        realphone: "",
        certType: "1",
        certNum: "",
        leaseTerm: "",
        sortleaseTerm: "",
        havePhone: '1',
        passWord: codeValue,
        cardFrontPic: [],
        passWordType: 'text',
        pwSearchFlag: false, //接口查询密码回来的标识
        searchCertChangePhoneFlag: false,
        haveCert: '1', //是否有证件号码
      };
    },
    methods: {
      havePhoneChange() {
        if (this.rowRoomForm.havePhone == '0') {
          this.rowRoomForm.passWord = codeValue;
        } else if (this.rowRoomForm.havePhone == '1') {
          this.rowRoomForm.realphone = '';
        }
      },
      haveCertChange() {
        if (this.rowRoomForm.haveCert == '0') {
          this.rowRoomForm.certType = '1';
          this.rowRoomForm.certNum = '';
          this.rowRoomForm.havePhone = '1';
        }
      },
      cohabitantHaveCertChange(val) {
        if (this.cohabitantList[val].haveCert == '0') {
          this.cohabitantList[val].cohabitantType = '1';
          this.cohabitantList[val].cohabitantCert = '';
          this.cohabitantList[val].havePhone = '1';
        }
      },
      cohabitantHavePhoneChange(val) {
        if (this.cohabitantList[val].havePhone == '0') {
          this.cohabitantList[val].passWord = codeValue;
        } else if (this.cohabitantList[val].havePhone == '1') {
          this.cohabitantList[val].cohabitantPhone = '';
        }
      },
      passWordFocus() {
        if (this.rowRoomForm.pwSearchFlag) {
          this.rowRoomForm.passWord = '';
          this.rowRoomForm.pwSearchFlag = false;
        }

      },
      passWordItemFocus(val) {
        if (this.cohabitantList[val].pwSearchFlag) {
          this.cohabitantList[val].passWord = '';
          this.cohabitantList[val].pwSearchFlag = false;
        }
      },
      dialogBtn() {
        let arr = this.cohabitantList[this.curRoommateIndex]['cardFrontPic'].length > 0 ? this.cohabitantList[this
          .curRoommateIndex]['cardFrontPic'][0].url.split('/') : ''
        this.cohabitantList[this.curRoommateIndex].cardText = arr.length > 0 ? arr[arr.length - 1] : '';
        this.dialogUplodCardShow = false;
      },
      uploadCardBtn(index) {
        this.curRoommateIndex = index;
        this.dialogUplodCardShow = true;
      },
      //配合图片上传确认item
      onPreviewByUploadImg(file) {
        this.dialogImageUrl = file.url;
        this.dialogImgVisible = true;
      },
      /**
       * 上传文件之前的钩子
       */
      onBeforeUpload() {
        return file => {
          let is = ["image/png", "image/jpeg"].indexOf(file.type) != -1;
          let size = file.size / 1024 / 1024 > 30;
          if (!is) {
            // console.log("上传文件之前的钩子", item, file, is);
            this.$message.error("只能上传图片");
            return false;
          } else if (size) {
            this.$message.error("上传的图片不能超过30M");
            return false;
          } else {
            return true;
          }

        };
      },
      //图片上传的图片删除
      onBeforeRemoveByUploadImg() {
        return (file, fileList) => {
          return new Promise((resolve, reject) => {
            let is = true;
            if (file.raw) {
              is = ["image/png", "image/jpeg"].indexOf(file.raw.type) != -1 && (file.raw.size / 1024 / 1024 < 30);
            }
            if (is) {
              this.$confirm("确认删除此图片吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                })
                .then(() => {
                  // item.opt.remove(file, fileList);
                  resolve();
                })
                .catch(function () {
                  reject();
                });
            } else {
              resolve();
            }
          });
        };
      },
      //房客表单图片单张上传
      onSuccessByUploadImgOnly(key, index) {
        return (response, file, fileList) => {
          let keyName = "picPath";
          if (index === undefined) {
            this.$set(this.rowRoomForm, key, [{
              url: response.data[keyName]
            }])

          } else {
            this.$set(this.cohabitantList[index], key, [{
              url: response.data[keyName]
            }])
          }
          this.$forceUpdate();
        };
      },
      // 房客图片删除
      onHandleRemove(key, index) {
        return (file, fileList) => {
          if (index == undefined) {
            this.rowRoomForm[key] = fileList;
          } else {
            this.cohabitantList[index][key] = fileList;
          }
          this.$forceUpdate();
        };
      },
      //文件上传数量超过限制
      onExceed() {
        this.$message({
          message: "文件上传数量超过限制，请删除后重新上传",
          type: "warning"
        });
      },
      certTypeChange(val) {
        tenantCertType = val;
      },
      cardReadingBtn(flag, idx) {
        let CertCtl = new IDCertCtl();
        let _this = this;
        //身份证读卡控件创建
        function IDCertCtl() {
          //创建用于与服务交换数据的对象
          this.xhr = createXmlHttp();
          this.type = "CertCtl";
          this.height = 0;
          this.width = 0;
          //读卡
          this.readCert = CertCtl_readCert;
        }
        //创建XMLHttpRequest 对象，用于在后台与服务器交换数据
        function createXmlHttp() {
          let xmlHttp = null;
          //根据window.XMLHttpRequest对象是否存在使用不同的创建方式
          if (window.XMLHttpRequest) {
            xmlHttp = new XMLHttpRequest(); //FireFox、Opera等浏览器支持的创建方式
          } else if (window.ActiveXObject) {
            xmlHttp = new ActiveXObject("Microsoft.XMLHTTP"); //IE浏览器支持的创建方式
          }
          xmlHttp.withCredentials = false;
          return xmlHttp;
        }
        //执行读卡操作
        function CertCtl_readCert() {
          let result = "";
          try {
            //创建请求 第一个参数是代表以post方式发送；第二个是请求端口和地址；第三个表示是否异步
            CertCtl.xhr.open("POST", "http://127.0.0.1:18889/api/readCard", false); //readCard读卡，不生成正反面仿复印件
            //发送请求
            CertCtl.xhr.send();
            if (CertCtl.xhr.readyState == 4 && CertCtl.xhr.status == 200) {
              result = CertCtl.xhr.responseText;
              CertCtl.xhr.readyState = 1;
            }
          } catch (e) {

          }
          return result;
        }
        let result = CertCtl.readCert();
        if (result == "") {
          this.$message({
            showClose: true,
            message: "未启动读卡插件!",
            type: "warning"
          });
        } else {
          let resultObj = eval('(' + result + ')');
          if (resultObj.resultFlag == "0") {
            //回显相关数据
            let resultContent = resultObj.resultContent;
            if (flag == '1') {
              _this.rowRoomForm.certNum = resultContent.certNumber;
              _this.rowRoomForm.realname = resultContent.partyName;
              _this.rowRoomForm.gender = resultContent.gender == 1 ? '1' : '2'; //读卡器读出来的gender值为 1男 0女
              _this.rowRoomForm.certType = '1';
              tenantCertType = '1';
              _this.getUserPhone(resultContent.certNumber).then(res => {
                let backResult = res.user;
                // if (result.phoneNum) {
                //   _this.rowRoomForm.realphone = result.phoneNum;
                //   _this.rowRoomForm.havePhone = '1';
                // }
                if (res.realState) //实名认证
                {
                  _this.isCertifiedTenant = true;
                  _this.searchohabitantCertFlage = true;
                  _this.lastAuthentication = true;
                } else {
                  if (_this.rowData.isKey == '1') {
                    return;
                  }
                  _this.isCertifiedTenant = false;
                  _this.searchohabitantCertFlage = false;
                  _this.lastAuthentication = false;
                }
                _this.rowRoomForm.realphone = backResult.phoneNum || "";

                _this.rowRoomForm.pwSearchFlag = true;
                if (backResult.phoneNum) {
                  _this.rowRoomForm.havePhone = '1';
                  _this.rowRoomForm.searchCertChangePhoneFlag = true;
                } else {
                  _this.rowRoomForm.havePhone = '0';
                  _this.rowRoomForm.passWord = codeValue;
                  _this.rowRoomForm.passWordType = 'password';
                  _this.rowRoomForm.searchCertChangePhoneFlag = false;
                }
              }).catch(() => {
                _this.rowRoomForm.realphone = '';
                _this.rowRoomForm.havePhone = '0';
              });
            } else if (flag == '2') {
              _this.cohabitantList[idx].cohabitantCert = resultContent.certNumber;
              _this.cohabitantList[idx].cohabitantName = resultContent.partyName;
              _this.cohabitantList[idx].studentCode = resultContent.studentCode;
              _this.cohabitantList[idx].cohabitantGender = resultContent.gender == 1 ? '1' : '2';;
              _this.cohabitantList[idx].cohabitantType = '1';
              _this.getUserPhone(resultContent.certNumber).then(res => {
                let backResult = res.user;
                // if (result.phoneNum) {
                //   _this.cohabitantList[idx].cohabitantPhone = result.phoneNum;
                //   _this.cohabitantList[idx].havePhone = '1';
                // }
                if (res.realState) {
                  _this.cohabitantList[idx].certifiedFlage = true;
                  _this.cohabitantList[idx].searchohabitantFlage = true;
                  _this.cohabitantList[idx].searchohabitantCertFlage = true;
                  _this.cohabitantList[idx].lastAuthentication = true;

                } else {
                  if (_this.rowData.isKey == '1') {
                    return;
                  }
                  _this.cohabitantList[idx].certifiedFlage = false;
                  _this.cohabitantList[idx].searchohabitantFlage = false;
                  _this.cohabitantList[idx].searchohabitantCertFlage = false;
                  _this.cohabitantList[idx].lastAuthentication = false;
                }
                _this.cohabitantList[idx].cohabitantPhone = backResult.phoneNum || "";
                _this.cohabitantList[idx].pwSearchFlag = true;
                if (backResult.phoneNum) {
                  _this.cohabitantList[idx].havePhone = '1';
                  _this.cohabitantList[idx].searchCertChangePhoneFlag = true;
                } else {
                  _this.cohabitantList[idx].havePhone = '0';
                  _this.cohabitantList[idx].passWord = codeValue;
                  _this.cohabitantList[idx].passWordType = 'password';
                  _this.cohabitantList[idx].searchCertChangePhoneFlag = false;
                }
              }).catch(() => {
                _this.cohabitantList[idx].cohabitantPhone = '';
                _this.cohabitantList[idx].havePhone = '0';
              });
            }
          } else if (resultObj.resultFlag == "-1") {
            if (resultObj.errorMsg == "端口打开失败") {
              _this.$message({
                showClose: true,
                message: "读卡器未连接",
                type: "warning"
              });
            } else {
              _this.$message({
                showClose: true,
                message: resultObj.errorMsg,
                type: "warning"
              });
            }
          } else if (resultObj.resultFlag == "-2") {
            _this.$message({
              showClose: true,
              message: resultObj.errorMsg,
              type: "warning"
            });
          }
        }
      },
      getUserPhone(query) {
        let params = {};
        params.idCardNo = query;
        let phoneNum = ''
        return new Promise((resolve, reject) => {
          this.post("user-service/user/config/query/info/" + query + "/id_card/account?filter=true", {}, {
            isNotShowError: true
          }).then(result => {
            resolve(result);
          }).catch((err) => {
            reject(err)
          })
        })

      },
      remoteMethod(query) {
        let phoneReg = /^1[3-9](\d){9}$/;
        if (query !== "" && (phoneReg.test(query))) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.getUserInfo(query, phoneReg.test(query));
          }, 200);
        } else {
          this.searchTenantOptions = [];
        }
      },
      remoteMethodCohabitant(query) {
        let phoneReg = /^1[3-9](\d){9}$/;
        if (query !== "" && phoneReg.test(query)) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.getCohabitantInfo(query, phoneReg.test(query));
          }, 200);
        } else {
          this.searchCohabitantOptions = [];
        }
      },
      getCohabitantInfo(query, flag) {
        this.post("user-service/user/config/query/info/" + query + "/mobile/account?filter=true", {}, {
          // isNotShowError: true
        }).then(result => {
          let res = result.user;
          let cardType = '';
          let cardNo = '';
          if (result.realState) //实名认证
          {
            cardType = res.idType;
            cardNo = res.idCardNo;
          } else {
            if (this.rowData.isKey == '1') {
              return;
            }
            if (result.accounts && result.accounts[0]) {
              cardNo = result.accounts[0].account;
              cardType = this.cardStrToNum(result.accounts[0].category);
            }else{
              cardType = res.idType;
              cardNo = res.idCardNo;
            }
          }
          let genderStr = res.gender == '1' ? '男' : '女';
          this.searchCohabitantOptions = [{
            value: (res.realName || "") +
              "|" +
              (res.phoneNum || "") +
              "|" +
              (cardType || "") +
              "|" +
              (cardNo || "")+
              "|" +
              (res.gender || "")+
              "|" +
              (this.rowData.apartmentType == '7'?(result.userInfoConfigMap.student_no?result.userInfoConfigMap.student_no:""):"" || ""),
            label: (res.realName || "") +
              "|" +
              (res.phoneNum || "") +
              "|" +
              (cardType || "") +
              "|" +
              (cardNo || "")+
              "|" +
              (genderStr || "")+
              "|" +
              (this.rowData.apartmentType == '7'?(result.userInfoConfigMap.student_no?result.userInfoConfigMap.student_no:""):"" || "")
          }];
        });
      },
      getUserInfo(query, flag) {
        this.post("user-service/user/config/query/info/" + query + "/mobile/account?filter=true", {}, {
          // isNotShowError: true
        }).then(result => {
          let res = result.user;
          let cardType = '';
          let cardNo = '';
          if (result.realState) //实名认证
          {
            cardType = res.idType;
            cardNo = res.idCardNo;
          } else {
            if (this.rowData.isKey == '1') {
              return;
            }
            if (result.accounts && result.accounts[0]) {
              cardNo = result.accounts[0].account;
              cardType = this.cardStrToNum(result.accounts[0].category);
            }else{
              cardType = res.idType;
              cardNo = res.idCardNo;
            }
          }
          let genderStr = res.gender == '1' ? '男' : '女';
          this.searchTenantOptions = [{
            value: (res.realName || "") +
              "|" +
              (res.phoneNum || "") +
              "|" +
              (cardType || "") +
              "|" +
              (cardNo || "")+
              "|" +
              (res.gender || "")+
              "|" +
              (this.rowData.apartmentType == '7'?(result.userInfoConfigMap.student_no?result.userInfoConfigMap.student_no:""):"" || ""),
            label: (res.realName || "") +
              "|" +
              (res.phoneNum || "") +
              "|" +
              (cardType || "") +
              "|" +
              (cardNo || "")+
              "|" +
              (genderStr || "")+
              "|" +
              (this.rowData.apartmentType == '7'?(result.userInfoConfigMap.student_no?result.userInfoConfigMap.student_no:""):"" || "")
          }];
        });
      },
      selectTrigger(val) {
        let arr = val.split("|");
        this.rowRoomForm.realname = arr[0];
        this.rowRoomForm.realphone = arr[1];
        this.rowRoomForm.certType = arr[2];
        this.rowRoomForm.gender = arr[4] ? arr[4] : '1';
        tenantCertType = arr[2];
        this.rowRoomForm.certNum = arr[3];
        this.rowRoomForm.studentCode = arr[5];
        if (this.rowRoomForm.realphone) {
          this.rowRoomForm.havePhone = '1';
          this.rowRoomForm.searchCertChangePhoneFlag = true;
        } else {
          this.rowRoomForm.havePhone = '0';
          this.rowRoomForm.passWord = codeValue;
          this.rowRoomForm.pwSearchFlag = true;
          this.rowRoomForm.searchCertChangePhoneFlag = false;
        }
        if (arr.length > 1 && arr[2] == '1') {
          //选择了房客  已认证
          this.isCertifiedTenant = true;
          this.searchohabitantPhoneFlage = true;
          this.searchohabitantCertFlage = true;
          this.lastAuthentication = true;
        } else {
          this.isCertifiedTenant = false;
          this.searchohabitantPhoneFlage = false;
          this.searchohabitantCertFlage = false;
        }
        if (arr[2] && arr[3]) //有证件号码
        {
          this.rowRoomForm.haveCert = '1';
        } else {
          this.rowRoomForm.haveCert = '0';
        }
        if (!arr[0] && !arr[1] && !arr[2] && !arr[3]) //清空时数据重置
        {
          this.rowRoomForm.haveCert = '1';
          this.rowRoomForm.havePhone = '1';
        }
        if (this.rowData.isKey == '1') {
          this.rowRoomForm.certType = '1';
        }
      },
      selectCohabitant(val, index) {
        let arr = val.split("|");
        this.cohabitantList[index].searchCohabitant = val ? val : "";
        this.cohabitantList[index].cohabitantName = arr[0] ? arr[0] : "";
        this.cohabitantList[index].cohabitantPhone = arr[1] ? arr[1] : "";
        this.cohabitantList[index].cohabitantType = arr[2] ? arr[2] : "";
        this.cohabitantList[index].cohabitantCert = arr[3] ? arr[3] : "";
        this.cohabitantList[index].cohabitantGender = arr[4] ? arr[4] : "1";
        this.cohabitantList[index].studentCode = arr[5] ? arr[5] : "";
        if (arr[2] == 1 && arr[3]) {
          this.cohabitantList[index].certifiedFlage = arr.length > 1 ? true : false;
          this.cohabitantList[index].searchohabitantFlage = arr.length > 1 ? true : false;
          this.cohabitantList[index].searchohabitantCertFlage = arr.length > 1 ? true : false;
          this.cohabitantList[index].lastAuthentication = arr.length > 1 ? true : false;
          this.cohabitantList[index].searchohabitantPhoneFlage = arr.length > 1 ? true : false;
        } else {
          this.cohabitantList[index].certifiedFlage = false;
          this.cohabitantList[index].searchohabitantFlage = false;
          this.cohabitantList[index].searchohabitantCertFlage = false;
          this.cohabitantList[index].lastAuthentication = false;
          this.cohabitantList[index].searchohabitantPhoneFlage = false;
        }
        if (arr[1]) {
          this.cohabitantList[index].havePhone = '1';
          this.cohabitantList[index].searchCertChangePhoneFlag = true;
        } else {
          this.cohabitantList[index].havePhone = '0';
          this.cohabitantList[index].passWord = codeValue;
          this.cohabitantList[index].pwSearchFlag = true;
          this.cohabitantList[index].searchCertChangePhoneFlag = false;
        }
        if (arr[2] && arr[3]) //有证件号码
        {
          this.cohabitantList[index].haveCert = '1';
        } else {
          this.cohabitantList[index].haveCert = '0';
        }
        if (!arr[0] && !arr[1] && !arr[2] && !arr[3]) //清空时数据重置
        {
          this.cohabitantList[index].haveCert = '1';
          this.cohabitantList[index].havePhone = '1';
        }
        if (this.rowData.isKey == '1') {
          this.cohabitantList[index].cohabitantType = '1';
        }

      },
      submitBtn(formName) {
        let _this = this;
        if (this.rowRoomForm.sortleaseTerm) {
          let nowTime = moment().format("YYYY-MM-DD");
          let nowTimeNum = moment().format("YYYY-MM-DD 14:00:00");
          let firstNowTime = moment(this.rowRoomForm.sortleaseTerm[0]).format(
            "YYYY-MM-DD"
          );
          let lastNowTime = moment(this.rowRoomForm.sortleaseTerm[1]).format(
            "YYYY-MM-DD"
          );
          if (Math.abs(moment(firstNowTime).diff(lastNowTime, "day")) > 100) {
            return this.$message({
              showClose: true,
              message: "短租租期不能超过100天",
              type: "warning"
            });
          }
          if (moment().format("YYYY-MM-DD HH:mm:ss") > nowTimeNum) {
            if (firstNowTime < nowTime || lastNowTime < nowTime) {
              return this.$message({
                showClose: true,
                message: "选择租期开始与结束日期不能小于当天日期",
                type: "warning"
              });
            } else if (firstNowTime > lastNowTime) {
              return this.$message({
                showClose: true,
                message: "选择租期开始日期小于结束日期",
                type: "warning"
              });
            } else if (firstNowTime == lastNowTime) {
              return this.$message({
                showClose: true,
                message: "选择租期结束日期大于开始日期",
                type: "warning"
              });
            }
          } else {
            let lastTime = moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD");
            if (firstNowTime < lastTime || lastNowTime < nowTime) {
              return this.$message({
                showClose: true,
                message: "选择租期开始与结束日期不能小于当天日期",
                type: "warning"
              });
            } else if (firstNowTime > lastNowTime) {
              return this.$message({
                showClose: true,
                message: "选择租期开始日期小于结束日期",
                type: "warning"
              });
            } else if (firstNowTime == lastNowTime) {
              return this.$message({
                showClose: true,
                message: "选择租期结束日期大于开始日期",
                type: "warning"
              });
            }
          }
        }
        this.$refs[formName].validate(valid => {
          if (valid) {
            if (this.rowRoomForm.haveCert == '1') {
              if (this.rowRoomForm.certType == "1") {
                if (!this.checkIdCard(this.rowRoomForm.certNum)) {
                  return this.$message({
                    showClose: true,
                    message: "请输入正确证件号码",
                    type: "warning"
                  });
                }
              } else if (this.rowRoomForm.certType == "7") {
                let reg = /^[A-z0-9]{4,24}$/;
                if (!reg.test(this.rowRoomForm.certNum)) {
                  return this.$message({
                    showClose: true,
                    message: "请输入正确学号/职工号",
                    type: "warning"
                  });
                }
              } else {
                let reg = /^[A-z0-9]{4,24}$/;
                if (!reg.test(this.rowRoomForm.certNum)) {
                  return this.$message({
                    showClose: true,
                    message: "请输入正确证件号码",
                    type: "warning"
                  });
                }
              }
            }

            if (this.rowRoomForm.sortleaseTerm[0]) {}

            for (let i = 0; i < this.cohabitantList.length; i++) {
              let room = JSON.parse(JSON.stringify(this.cohabitantList[i]));
              if (room.cohabitantName != "") {
                let realNameReg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
                if (!realNameReg.test(room.cohabitantName)) {
                  return this.$message({
                    showClose: true,
                    message: "序号为" + (i + 1) + "中的同住人姓名格式错误",
                    type: "warning"
                  });
                }
                if (room.havePhone == '1') {
                  if (room.cohabitantPhone == "") {
                    return this.$message({
                      showClose: true,
                      message: "请输入序号为" + (i + 1) + "中的同住人电话",
                      type: "warning"
                    });
                  } else {
                    let reg = /^1[3-9](\d){9}$/;
                    if (!reg.test(room.cohabitantPhone)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确同住人电话",
                        type: "warning"
                      });
                    }
                  }
                } else if (room.havePhone == '0') {
                  if (room.passWord == "") {
                    return this.$message({
                      showClose: true,
                      message: "请输入序号为" + (i + 1) + "中的登录密码",
                      type: "warning"
                    });
                  } else {
                    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,12}$/;
                    let reg2 = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
                    if (reg2.test(room.passWord)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确登录密码",
                        type: "warning"
                      });
                    } else if (!reg.test(room.passWord)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确登录密码",
                        type: "warning"
                      });
                    }
                  }

                }
                if (room.haveCert == '1') {
                  if (room.cohabitantCert == "") {
                    return this.$message({
                      showClose: true,
                      message: "请输入序号为" + (i + 1) + "中的同住人" + (room.cohabitantType == "7" ? "学号/职工号" : "证件号码"),
                      type: "warning"
                    });
                  } else {
                    if (room.cohabitantType == "1") {
                      if (!this.checkIdCard(room.cohabitantCert)) {
                        return this.$message({
                          showClose: true,
                          message: "请在序号为" + (i + 1) + "中的输入正确同住人证件号码",
                          type: "warning"
                        });
                      }
                    } else if (room.cohabitantType == "7") {
                      let reg = /^[A-z0-9]{4,24}$/;
                      if (!reg.test(room.cohabitantCert)) {
                        return this.$message({
                          showClose: true,
                          message: "请在序号为" + (i + 1) + "中的输入正确同住人学号/职工号",
                          type: "warning"
                        });
                      }
                    } else {
                      let reg = /^[A-z0-9]{4,24}$/;
                      if (!reg.test(room.cohabitantCert)) {
                        return this.$message({
                          showClose: true,
                          message: "请在序号为" + (i + 1) + "中的输入正确同住人证件号码",
                          type: "warning"
                        });
                      }
                    }
                  }
                }
                if (room.cardFrontPic.length !== 1 && this.rowData.isUploadPic == '1') {
                  return this.$message({
                    showClose: true,
                    message: "请在序号为" + (i + 1) + "中的上传证件照片",
                    type: "warning"
                  });
                }
                if(this.rowData.apartmentType == '7' && room.cohabitantType!=='7') {
                  if(room.studentCode){
                    let reg = /^[A-Za-z0-9]{4,24}$/;
                    if (!reg.test(room.studentCode)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确同住人学号/职工号",
                        type: "warning"
                      });
                    }
                  }
                }
              }
            }
            let dtoDatas = [];
            let dto = this.cohabitantList;
            for (let i = 0; i < dto.length; i++) {
              if (dto[i].cohabitantName != "") {
                let objData = objData = {
                  userName: dto[i].cohabitantName,
                  gender: dto[i].cohabitantGender,
                  cardType: dto[i].haveCert === '0' ? '' : dto[i].cohabitantType,
                  phoneNumber: dto[i].havePhone == '1' ? dto[i].cohabitantPhone : '',
                  idcardno: dto[i].haveCert === '0' ? '' : dto[i].cohabitantCert,
                  certifiedFlage: dto[i].certifiedFlage,
                  idCardPic1: dto[i].cardFrontPic.length > 0 ? dto[i].cardFrontPic[0].url : '',
                  havePhone: dto[i].havePhone,
                  password: (dto[i].havePhone == '1' || dto[i].pwSearchFlag || dto[i].haveCert === '0') ? '' :
                    _this.AES.encrypt(dto[i]
                      .passWord),
                };
                if (this.rowData.apartmentCode && dto[i].cohabitantType == '7') {
                  objData.code = this.rowData.apartmentCode
                }
                if(this.rowData.apartmentType == 7) {
                  if(dto[i].cohabitantType == '7'){
                    objData.studentCode = dto[i].cohabitantCert
                  }else{
                    objData.studentCode = dto[i].studentCode
                  }
                }
                dtoDatas.push(objData);
              }
            }
            for (let h = 0; h < dtoDatas.length; h++) {
              if (this.rowRoomForm.realphone && dtoDatas[h].phoneNumber && this.rowRoomForm.realphone ==
                dtoDatas[h].phoneNumber) {
                return this.$message({
                  showClose: true,
                  message: "同住人电话与房客电话相同",
                  type: "warning"
                });
              } else if (this.rowRoomForm.certNum && dtoDatas[h].idcardno && this.rowRoomForm.certNum == dtoDatas[h].idcardno) {
                return this.$message({
                  showClose: true,
                  message: "同住人" + (dtoDatas[h].cardType == "7" ? "学号/职工号" : "证件号码") + "与房客" + (this.rowRoomForm
                    .certType == "7" ? "学号/职工号" : "证件号码") + "相同",
                  type: "warning"
                });
              }
            }
            for (let j = 0; j < dtoDatas.length; j++) {
              if (
                dtoDatas[j] &&
                dtoDatas[j + 1] &&
                dtoDatas[j].phoneNumber &&
                dtoDatas[j + 1].phoneNumber &&
                dtoDatas[j].phoneNumber == dtoDatas[j + 1].phoneNumber
              ) {
                return this.$message({
                  showClose: true,
                  message: "序号" + (j + 1) + "与" + (j + 2) + "中的同住人电话相同",
                  type: "warning"
                });
              } else if (
                dtoDatas[j] && dtoDatas[j + 1] && dtoDatas[j].idcardno && dtoDatas[j].idcardno == dtoDatas[j + 1].idcardno) {
                return this.$message({
                  showClose: true,
                  message: "序号" + (j + 1) + "与" + (j + 2) + "中的同住人" + (dtoDatas[j].cardType == '7' ? '学号/职工号' :
                    '证件号码') + "相同",
                  type: "warning"
                });
              }
            }
            let dataList = {
              roomId: this.rowData.roomId,
              rentalStyle: this.rowData.rentalStyle == 2 ?
                this.rowRoomForm.rentalStyle : this.rowData.rentalStyle,
              orderFrom: this.rowRoomForm.orderFrom, //订单来源
              userName: this.rowRoomForm.realname,
              gender: this.rowRoomForm.gender,
              phoneNumber: this.rowRoomForm.havePhone == '1' ? this.rowRoomForm.realphone : '',
              cardType: this.rowRoomForm.haveCert === '0' ? '' : this.rowRoomForm.certType,
              idcardno: this.rowRoomForm.haveCert === '0' ? '' : this.rowRoomForm.certNum,
              idCardPic1: this.rowRoomForm.cardFrontPic.length > 0 ? this.rowRoomForm.cardFrontPic[0].url : '',
              havePhone: this.rowRoomForm.havePhone,
              password: (this.rowRoomForm.havePhone == '1' || this.rowRoomForm.pwSearchFlag || this.rowRoomForm
                  .haveCert === '0') ? '' : _this.AES
                .encrypt(this.rowRoomForm.passWord),
              userList: dtoDatas
            };
            if(this.rowData.apartmentType == 7) {
              dataList.isSchool = true
              if(this.rowRoomForm.certType == '7'){
                dataList.studentCode = this.rowRoomForm.certNum
              }else{
                dataList.studentCode = this.rowRoomForm.studentCode
              }
            }else{
              dataList.isSchool = false
            }
            // console.log('dataList', dataList);
            // return;
            if (this.rowData.apartmentCode && this.rowRoomForm.certType == '7') {
              dataList.code = this.rowData.apartmentCode
            }
            if (!this.isCertifiedTenant) {
              dataList.certifiedFlage = false; //未认证
            } else {
              dataList.certifiedFlage = true; //已认证
            }
            if (this.rowRoomForm.realNum != "") {
              dataList.orderNo = this.rowRoomForm.realNum;
            }
            if (this.rowRoomForm.realMoney != "") {
              dataList.orderAmount = this.rowRoomForm.realMoney;
            }
            if (dataList.rentalStyle == 0) {
              dataList.leaseTerm = this.rowRoomForm.leaseTerm;
            } else {
              dataList.startDate = this.rowRoomForm.sortleaseTerm[0];
              dataList.endDate = this.rowRoomForm.sortleaseTerm[1];
            }
            this.post("landlord-service/room/planRoom", dataList, {
              isLoading: true,
              isUseResponse: true,
              operatorFlage: true,
              operateName: '排房',
              isNotShowError: true
            }).then(res => {
              this.$message({
                showClose: true,
                message: "排房成功",
                type: "success"
              });
              this.$router.push("/main/room/list");
            }).catch(err => {
              if(err.data.code == 999226){
                _this.$alert(err.data.msg, '学号/职工号重复', {
                  confirmButtonText: '确定',
                  callback: action => {
                    _this.alertFlag = false
                  }
                });
              }else{
                this.$message({
                  showClose: true,
                  message: err.data.msg,
                  type: "warning"
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      changeSelect(value) {
        this.LeasingModel = value;
        if(value == '0'){
          this.rowRoomForm.leaseTerm = 12
          this.rowRoomForm.sortleaseTerm = ""
        }else{
          this.rowRoomForm.sortleaseTerm = this.timeDefault()
          this.rowRoomForm.leaseTerm = ""
        }
      },
      //增加行
      addItem(idx) {
        if (this.cohabitantList.length == 5) {
          this.$message({
            showClose: true,
            message: "最多添加至5项",
            type: "warning"
          });
        } else {
          this.cohabitantList.splice(
            idx + 1,
            0,
            JSON.parse(JSON.stringify(this.roomTemplate))
          );
        }
      },
      //删除行
      delItem(idx) {
        if (this.cohabitantList.length == 1) {
          this.$message({
            showClose: true,
            message: "至少保留1项",
            type: "warning"
          });
        } else {
          this.cohabitantList.splice(idx, 1);
          this.curRoommateIndex = 0;
        }
      },
      timeDefault() {
        let date = new Date();
        // 通过时间戳计算
        let defalutStartTime = date.getTime(); // 转化为时间戳
        let defalutEndTime = date.getTime() + 1 * 24 * 3600 * 1000;
        let startDateNs = new Date(defalutStartTime);
        let endDateNs = new Date(defalutEndTime);
        // 月，日 不够10补0
        defalutStartTime =
          startDateNs.getFullYear() +
          "-" +
          (startDateNs.getMonth() + 1 >= 10 ?
            startDateNs.getMonth() + 1 :
            "0" + (startDateNs.getMonth() + 1)) +
          "-" +
          (startDateNs.getDate() >= 10 ?
            startDateNs.getDate() :
            "0" + startDateNs.getDate());
        defalutEndTime =
          endDateNs.getFullYear() +
          "-" +
          (endDateNs.getMonth() + 1 >= 10 ?
            endDateNs.getMonth() + 1 :
            "0" + (endDateNs.getMonth() + 1)) +
          "-" +
          (endDateNs.getDate() >= 10 ?
            endDateNs.getDate() :
            "0" + endDateNs.getDate());
        return [defalutStartTime, defalutEndTime];
      },
      //获取验证码
      send(val, val2, index) {
        //val2=1--房客 val2=2--同居人 
        console.log('获取验证码')
        let _this = this;
        if (!val) {
          return _this.$message({
            showClose: true,
            message: "请输入手机号码",
            type: "warning"
          });
        }
        //验证码在60秒之内只能点击一次
        if (val2 == 1 && _this.showTime) {
          return;
        } else if (val2 == 2 && _this.cohabitantList[index].showTime) {
          return;
        }
        let dto = {
          mobile: val
        };
        _this.post("user-service/weChat/sendMobileCode", dto, {}).then(res => {
          if (val2 == 1) {
            _this.count = TIME_COUNT;
            _this.showTime = true;
            _this.timer = setInterval(() => {
              if (_this.count > 0 && _this.count <= TIME_COUNT) {
                _this.count--;
              } else {
                _this.showTime = false;
                clearInterval(_this.timer); // 清除定时器
                _this.timer = null;
              }
            }, 1000);
          } else if (val2 == 2) {
            _this.$set(_this.cohabitantList[index], 'showTime', true);
            _this.$set(_this.cohabitantList[index], 'count', TIME_COUNT);
            _this.cohabitantList[index].timer = setInterval(() => {
              if (_this.cohabitantList[index].count > 0 && _this.cohabitantList[index].count <= TIME_COUNT) {
                _this.cohabitantList[index].count = _this.cohabitantList[index].count - 1;
                _this.$set(_this.cohabitantList[index], 'count', _this.cohabitantList[index].count);
              } else {
                _this.$set(_this.cohabitantList[index], 'showTime', false);
                clearInterval(_this.cohabitantList[index].timer); // 清除定时器
                _this.$set(_this.cohabitantList[index], 'timer', null);
              }
            }, 1000);
          }
        });
      },
      //根据手机号码查询是否为认证房客
      searchRealphoneTenant(queryString) {
        var results = [];
        let phoneReg = /^1[3-9](\d){9}$/;
        if (this.rowRoomForm.haveCert == '0') {
          return;
        }
        if (queryString == "") {
          //是否已认证
          this.isCertifiedTenant = false;
          this.searchohabitantPhoneFlage = false;
          this.rowRoomForm.searchCertChangePhoneFlag = false;
          this.rowRoomForm.realname = '';
          this.rowRoomForm.studentCode = '';
          this.rowRoomForm.realphone = '';
          this.rowRoomForm.certType = '';
          tenantCertType = '';
          this.rowRoomForm.certNum = '';
          if (this.rowData.isKey == '1') {
            this.rowRoomForm.certType = '1';
          }
          return;
        }
        if (phoneReg.test(queryString)) {
          this.post("user-service/user/config/query/info/" + queryString + "/mobile/account?filter=true", {}, {
            isNotShowError: true,
            isUseResponse: true,
          }).then(data => {
            if (data.data.code == 0) {
              let result = data.data.data;
              let res = result.user;
              //是否已认证
              if (result.realState) //已实名
              {
                this.isCertifiedTenant = true;
                this.searchohabitantPhoneFlage = true;
                this.lastAuthentication = true;
                this.rowRoomForm.certType = res.idType + '';
                tenantCertType = res.idType + '';
                this.rowRoomForm.certNum = res.idCardNo;
              } else {
                if (this.rowData.isKey == '1') {
                  return;
                }
                this.lastAuthentication = false;
                if (result.accounts && result.accounts[0]) {
                  this.rowRoomForm.certNum = result.accounts[0].account;
                  this.rowRoomForm.certType = this.cardStrToNum(result.accounts[0].category);
                  tenantCertType = this.cardStrToNum(result.accounts[0].category);
                }else{
                  this.rowRoomForm.certType = res.idType + '';
                  tenantCertType = res.idType + '';
                  this.rowRoomForm.certNum = res.idCardNo;
                }
              }
              this.rowRoomForm.realname = res.realName;
              this.rowRoomForm.studentCode = result.userInfoConfigMap.student_no;
              this.rowRoomForm.realphone = res.phoneNum;
              this.rowRoomForm.gender = res.gender.toString();
              if (res.phoneNum) {
                this.rowRoomForm.havePhone = '1';
                this.rowRoomForm.searchCertChangePhoneFlag = true;
              } else {
                this.rowRoomForm.passWord = codeValue;
                this.rowRoomForm.pwSearchFlag = true;
                this.rowRoomForm.searchCertChangePhoneFlag = false;
              }
            } else {
              if (this.lastAuthentication) {
                this.rowRoomForm.gender = '1';
                this.rowRoomForm.certType = '';
                tenantCertType = '';
                this.rowRoomForm.certNum = '';
                this.rowRoomForm.realname = '';
                this.rowRoomForm.studentCode = '';
                this.rowRoomForm.searchCertChangePhoneFlag = false;
                this.lastAuthentication = false;
              }
            }
          }).catch(err => {
            if (this.lastAuthentication) {
              this.rowRoomForm.certType = '';
              tenantCertType = '';
              this.rowRoomForm.certNum = '';
              this.rowRoomForm.realname = '';
              this.rowRoomForm.studentCode = '';
              this.rowRoomForm.searchCertChangePhoneFlag = false;
              this.lastAuthentication = false;
            }
            //是否已认证
            this.isCertifiedTenant = false;
            this.searchohabitantPhoneFlage = false;
          })
        }
      },
      //根据同居人手机号码查询是否为已认证
      searchRealphoneCohabitant(queryString, index) {
        var results = [];
        let phoneReg = /^1[3-9](\d){9}$/;
        if (this.cohabitantList[index].haveCert == '0') //是否有证件号码为无时，不根据手机号码查询
        {
          return;
        }
        if (queryString == "") {
          this.cohabitantList[index].certifiedFlage = false;
          this.cohabitantList[index].searchohabitantFlage = false;
          this.cohabitantList[index].searchohabitantPhoneFlage = false;
          this.cohabitantList[index].searchCertChangePhoneFlag = false;
          this.cohabitantList[index].cohabitantName = "";
          this.cohabitantList[index].cohabitantGender = "1";
          this.cohabitantList[index].cohabitantType = "";
          this.cohabitantList[index].cohabitantCert = "";
          this.cohabitantList[index].studentCode = "";
          if (this.rowData.isKey == '1') {
            this.cohabitantList[index].cohabitantType = "1";
          }
          return;
        }
        if (phoneReg.test(queryString)) {
          this.post("user-service/user/config/query/info/" + queryString + "/mobile/account?filter=true", {}, {
            isNotShowError: true,
            isUseResponse: true,
          }).then(data => {
            if (data.data.code == 0) {
              let result = data.data.data;
              let res = result.user;
              if (result.realState) {
                this.cohabitantList[index].certifiedFlage = true;
                this.cohabitantList[index].searchohabitantFlage = true;
                this.cohabitantList[index].searchohabitantPhoneFlage = true;
                this.cohabitantList[index].lastAuthentication = true;
                this.cohabitantList[index].cohabitantType = res.idType + "" || "";
                this.cohabitantList[index].cohabitantCert = res.idCardNo || "";
              } else {
                if (this.rowData.isKey == '1') {
                  return;
                }
                if (result.accounts && result.accounts[0]) {
                  this.cohabitantList[index].cohabitantCert = result.accounts[0].account;
                  this.cohabitantList[index].cohabitantType = this.cardStrToNum(result.accounts[0].category);
                }else{
                  this.cohabitantList[index].cohabitantType = res.idType + "" || "";
                  this.cohabitantList[index].cohabitantCert = res.idCardNo || "";
                }
                this.cohabitantList[index].certifiedFlage = false;
                this.cohabitantList[index].searchohabitantFlage = false;
                this.cohabitantList[index].searchohabitantPhoneFlage = false;
                this.cohabitantList[index].lastAuthentication = false;
              }
              this.cohabitantList[index].cohabitantName = res.realName || "";
              this.cohabitantList[index].studentCode = result.userInfoConfigMap.student_no || "";
              this.cohabitantList[index].cohabitantPhone = res.phoneNum || "";
              this.cohabitantList[index].cohabitantGender = res.gender.toString() || "1";
              if (res.phoneNum) {
                this.cohabitantList[index].havePhone = '1';
                this.cohabitantList[index].searchCertChangePhoneFlag = true;
              } else {
                this.cohabitantList[index].passWord = codeValue;
                this.cohabitantList[index].pwSearchFlag = true;
                this.cohabitantList[index].searchCertChangePhoneFlag = false;
              }
            } else {
              if (this.cohabitantList[index].lastAuthentication) {
                this.cohabitantList[index].cohabitantName = "";
                this.cohabitantList[index].studentCode = "";
                this.cohabitantList[index].cohabitantPhone = "";
                this.cohabitantList[index].cohabitantType = "";
                this.cohabitantList[index].cohabitantCert = "";
                this.cohabitantList[index].searchCertChangePhoneFlag = false;
                this.cohabitantList[index].cohabitantGender = "1";
              }
            }
          }).catch(err => {
            if (this.cohabitantList[index].lastAuthentication) {
              this.cohabitantList[index].cohabitantName = "";
              this.cohabitantList[index].studentCode = "";
              this.cohabitantList[index].cohabitantType = "";
              this.cohabitantList[index].cohabitantCert = "";
              this.cohabitantList[index].searchCertChangePhoneFlag = false;
              this.cohabitantList[index].lastAuthentication = false;
              this.cohabitantList[index].cohabitantGrnder = "1";
            }
            this.cohabitantList[index].certifiedFlage = false;
            this.cohabitantList[index].searchohabitantFlage = false;
            this.cohabitantList[index].searchohabitantPhoneFlage = false;
          })
        }
      },
      //房客查询证件号码
      searchCertNumTenantData(obj) {
        let queryString = obj.certNum
        if (queryString == "") {
          return;
        }
        this.post('user-service/user/config/query/info/' + obj.certNum + '/' + this.certTypeString[this.rowRoomForm
          .certType] + '/account?filter=true', {}, {
          isNotShowError: true,
          isUseResponse: true
        }).then(data => {
          if (data.data.code == 0) {
            let result = data.data.data;
            let res = result.user;
            if (result.realState) //实名认证
            {
              this.rowRoomForm.certType = res.idType + "" || "";
              tenantCertType = res.idType + "" || "";
              this.rowRoomForm.certNum = res.idCardNo || "";
              this.isCertifiedTenant = true;
              this.searchohabitantCertFlage = true;
              this.lastAuthentication = true;
            } else {
              if (this.rowData.isKey == '1') {
                return;
              }
              this.isCertifiedTenant = false;
              this.searchohabitantCertFlage = false;
              this.lastAuthentication = false;
            }
            this.rowRoomForm.realname = res.realName || "";
            this.rowRoomForm.studentCode = result.userInfoConfigMap.student_no || "";
            this.rowRoomForm.realphone = res.phoneNum || "";
            this.rowRoomForm.gender = res.gender.toString() || "1";

            this.rowRoomForm.pwSearchFlag = true;
            if (res.phoneNum) {
              this.rowRoomForm.havePhone = '1';
              this.rowRoomForm.searchCertChangePhoneFlag = true;
            } else {
              this.rowRoomForm.havePhone = '0';
              this.rowRoomForm.passWord = codeValue;
              this.rowRoomForm.passWordType = 'password';
              this.rowRoomForm.searchCertChangePhoneFlag = false;
            }
          } else {
            if (this.lastAuthentication) {
              this.rowRoomForm.realname = "";
              this.rowRoomForm.studentCode = "";
              this.rowRoomForm.realphone = "";
              this.rowRoomForm.searchCertChangePhoneFlag = false;
              this.isCertifiedTenant = false;
              this.searchohabitantCertFlage = false;
              this.lastAuthentication = false;
            }
          }
        }).catch(err => {
          //是否已认证
          if (this.lastAuthentication) {
            this.rowRoomForm.realname = "";
            this.rowRoomForm.studentCode = "";
            this.rowRoomForm.realphone = "";
            this.rowRoomForm.searchCertChangePhoneFlag = false;
            this.lastAuthentication = false;
          }
          this.isCertifiedTenant = false;
          this.searchohabitantCertFlage = false;

          // this.lastAuthentication = false;
        })
      },
      //根据证件号码查询是否为认证房客
      searchCertNumTenant(obj, flag) {
        let queryString = obj.certNum
        if (queryString == "" && this.searchohabitantCertFlage) {
          //是否已认证
          this.isCertifiedTenant = false;
          this.searchohabitantCertFlage = false;
          this.lastAuthentication = false;
          this.rowRoomForm.searchCertChangePhoneFlag = false;
          this.rowRoomForm.realname = '';
          this.rowRoomForm.studentCode = '';
          this.rowRoomForm.realphone = '';
          this.rowRoomForm.havePhone = '1';
          this.rowRoomForm.passWord = codeValue;
          return;
        }
        if (this.checkIdCard(queryString) && flag == '1') //身份证
        {
          this.searchCertNumTenantData(obj)
        } else if (flag == '2') //失去焦点
        {
          this.searchCertNumTenantData(obj)
        }
      },
      //同住人查询证件号码
      blurCertNumCohabitantData(obj, index, flag) {
        let queryString = obj.cohabitantCert;
        if (queryString == "") {
          return;
        }
        this.post('user-service/user/config/query/info/' + queryString + '/' + this.certTypeString[this
          .cohabitantList[index].cohabitantType] + '/account?filter=true', {}, {
          isNotShowError: true,
          isUseResponse: true
        }).then(data => {
          if (data.data.code == 0) {
            let result = data.data.data;
            let res = result.user;
            if (result.realState) {
              this.cohabitantList[index].certifiedFlage = true;
              this.cohabitantList[index].searchohabitantFlage = true;
              this.cohabitantList[index].searchohabitantCertFlage = true;
              this.cohabitantList[index].lastAuthentication = true;
              this.cohabitantList[index].cohabitantType = res.idType + "" || "";
              this.cohabitantList[index].cohabitantCert = res.idCardNo || "";

            } else {
              if (this.rowData.isKey == '1') {
                return;
              }
              this.cohabitantList[index].certifiedFlage = false;
              this.cohabitantList[index].searchohabitantFlage = false;
              this.cohabitantList[index].searchohabitantCertFlage = false;
              this.cohabitantList[index].lastAuthentication = false;
            }
            this.cohabitantList[index].cohabitantName = res.realName || "";
            this.cohabitantList[index].studentCode = result.userInfoConfigMap.student_no || "";
            this.cohabitantList[index].cohabitantPhone = res.phoneNum || "";
            this.cohabitantList[index].pwSearchFlag = true;
            this.cohabitantList[index].cohabitantGender = res.gender.toString() || "1";
            if (res.phoneNum) {
              this.cohabitantList[index].havePhone = '1';
              this.cohabitantList[index].searchCertChangePhoneFlag = true;
            } else {
              this.cohabitantList[index].havePhone = '0';
              this.cohabitantList[index].passWord = codeValue;
              this.cohabitantList[index].passWordType = 'password';
              this.cohabitantList[index].searchCertChangePhoneFlag = false;
            }
          } else {
            if (this.cohabitantList[index].lastAuthentication) {
              this.cohabitantList[index].cohabitantName = "";
              this.cohabitantList[index].studentCode = "";
              this.cohabitantList[index].cohabitantGender = "1";
              this.cohabitantList[index].cohabitantPhone = "";
              this.cohabitantList[index].searchCertChangePhoneFlag = false;
              this.cohabitantList[index].havePhone = '0';
              this.cohabitantList[index].passWord = codeValue;
              this.cohabitantList[index].lastAuthentication = false;
            }
          }
        }).catch(err => {
          if (this.cohabitantList[index].lastAuthentication) {
            this.cohabitantList[index].cohabitantName = "";
            this.cohabitantList[index].studentCode = "";
            this.cohabitantList[index].cohabitantGender = "1";
            this.cohabitantList[index].cohabitantPhone = "";
            this.cohabitantList[index].searchCertChangePhoneFlag = false;
            this.cohabitantList[index].havePhone = '0';
            this.cohabitantList[index].passWord = codeValue;
            this.cohabitantList[index].lastAuthentication = false;
          }
          this.cohabitantList[index].certifiedFlage = false;
          this.cohabitantList[index].searchohabitantFlage = false;
          this.cohabitantList[index].searchohabitantCertFlage = false;

        })
      },
      searchCertNumCohabitant(obj, index, flag) {
        var results = [];
        let sfzReg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        let queryString = obj.cohabitantCert;
        let dto = {
          idCardNo: queryString
        };
        if (queryString == "" && this.cohabitantList[index].searchohabitantCertFlage) {
          this.cohabitantList[index].certifiedFlage = false;
          this.cohabitantList[index].searchohabitantFlage = false;
          this.cohabitantList[index].searchohabitantCertFlage = false;
          this.cohabitantList[index].lastAuthentication = false;
          this.cohabitantList[index].searchCertChangePhoneFlag = false;
          this.cohabitantList[index].cohabitantName = "";
          this.cohabitantList[index].studentCode = "";
          this.cohabitantList[index].cohabitantGender = "1";
          this.cohabitantList[index].cohabitantPhone = "";
          this.cohabitantList[index].havePhone = '1';
          this.cohabitantList[index].passWord = codeValue;
          return;
        }
        if (this.checkIdCard(queryString) && flag == '1') {
          this.blurCertNumCohabitantData(obj, index)
        } else if (flag == '2') //失去焦点
        {
          this.blurCertNumCohabitantData(obj, index)
        }
      },
      //同居人 获取验证码
      sendCohabitantCode(val, index) {
        if (!val) {
          return this.$message({
            showClose: true,
            message: "请输入同住人电话",
            type: "warning"
          });
        }
        this.send(val, 2, index);
      },

    }
  };
</script>
<style lang="scss">
  .Rowhouse_list {
    .content-top {
      width: 100%;
      height: 36px;
      line-height: 36px;
      background: rgba(242, 242, 242, 1);
      color: #666666;
      margin-bottom: 20px;
      padding-left: 10px;
      box-sizing: border-box;

      span {
        padding-left: 10px;
        padding-right: 10px;
      }

      label {
        color: #ff0000;
      }
    }

    .content-box {
      .form-box {
        padding-right: 20px;

        .width70 {
          .el-input {
            width: 70px;
          }

          .el-date-editor--daterange.el-input__inner {
            width: 230px;
          }
        }

        .width90 {
          .el-input {
            width: 90px;
          }

          .el-date-editor--daterange.el-input__inner {
            width: 230px;
          }
        }

        .list-box {
          // margin: 0 auto;
          min-width: 1250px;

          .list-item {
            padding: 4px 0;
            margin-bottom: 6px;
            background-color: #eee;
            padding-bottom: 12px;
            padding-right: 135px;
            position: relative;

            .list-idx {
              display: inline-block;
              width: 40px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              font-size: 14px;
              color: #606266;
            }

            .list-col {
              display: inline-block;
              margin-top: 10px;

              label {
                margin-left: 10px;
                margin-right: 6px;
                font-size: 14px;
                color: #606266;
                line-height: 40px;
              }

              .el-input {
                width: 150px;
              }

              .el-name {
                width: 120px;
              }

              .el-autocomplete {
                .el-input {
                  width: 180px;
                }
              }

              .inputsCal {
                width: 200px;
              }

              .el-inputs {
                width: 200px;
              }

              .el-btn {
                margin-left: 10px;
              }

              .el-select {
                display: inline-block !important;
              }

              .verCodeInput {
                width: 100px;
              }

              .verCodeText {
                color: #30BAC1;
                font-size: 14px;
                margin-left: 10px;
                cursor: pointer;
              }

              .width100 {
                display: inline-block;

                .el-input {
                  width: 100px;
                }

                .el-date-editor--daterange.el-input__inner {
                  width: 230px;
                }
              }
            }

            .list-buts {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              // float: right;
              // padding-right: 10px;
            }
          }
        }

        .dashed-box {
          width: 100%;
          border-top: 1px dashed #cccccc;
          margin-top: 20px;
          margin-bottom: 12px;
        }

        .lt-select {
          width: 200px;
          margin-right: 10px;
        }

        .fl {
          float: left;
        }

        .rt-btn {
          float: right;
        }
      }

      .btn-box {
        padding-left: 20px;
        margin-bottom: 20px;
      }

      .description {
        color: #999999;
      }

      .picCont {
        display: flex;

        .pic-lt,
        .pic-rt {
          p {
            text-align: center;
            color: #999999;
            line-height: 30px;
            margin: 0;
            padding: 0;
          }
        }

        .pic-lt {
          margin-right: 15px;
        }

        .avatar-uploader {
          width: 148px;
          height: 148px;
        }
      }
    }

    .el-dialog {
      margin-top: 30vh !important;
    }

    .el-time-panel {
      display: none !important;
    }

    .verificationCode {
      margin-left: 10px;
      color: #30BAC1;
      cursor: pointer;
    }

    .bottom-box {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      .bottom_boxBtn {
        width: 130px;
      }
    }
  }
</style>